import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me_icon.jpg`} alt="" />
      </Link>
      <header>
        <h2>Brandon Lu</h2>
        <p><a href="mailto:lubrandon@proton.me">lubrandon@proton.me</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>
        Hello! My name is Brandon Lu. <br /><br />
        I&apos;m a Software Developer and a CS graduate from the University of Calgary based in
        Alberta, Canada.<br /><br />
        I enjoy learning about a variety of things. From server deployments to software development,
        I have an interest in many fields and love to keep learning!<br /><br />
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Brandon Lu <Link to="/">lubrandon.com</Link>.</p>
    </section>
  </section>
);

export default SideBar;
