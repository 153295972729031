import {
  faGithub, faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

// import faInstagram from '@fortawesome/fontawesome-free-brands/faInstagram';
// import faLinkedinIn from '@fortawesome/fontawesome-free-brands/faLinkedinIn';
// import faTwitter from '@fortawesome/fontawesome-free-brands/faTwitter';
// import faEnvelope from '@fortawesome/fontawesome-free-regular/faEnvelope';

const data = [
  {
    link: 'https://github.com/keyboardcrash',
    label: 'Github',
    icon: faGithub,
  },
  {
    link: 'https://www.linkedin.com/in/lubrandon',
    label: 'LinkedIn',
    icon: faLinkedin,
  },
  {
    link: 'mailto:lubrandon@proton.me',
    label: 'Email',
    icon: faEnvelope,
  },
  /*
  {
    link: 'https://www.instagram.com/bigdumbbrandon/',
    label: 'Instagram',
    icon: faInstagram,
  },
  {
    link: 'https://twitter.com/bigdumbbrandon',
    label: 'Twitter',
    icon: faTwitter,
  },
  */
];

export default data;
